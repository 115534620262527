import React, { useReducer, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Form, Button, ListGroup, Col, Row } from 'react-bootstrap';

import usePost from '../Hooks/usePost'


//import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextArea from '@uiw/react-md-editor/lib/components/TextArea';
import TextEditor from 'components/TextEditor';
import CaptureAction from 'components/CaptureAction';
import ActionList from 'components/ActionList';


const formReducer = (state, event) => {

    console.log("Loging int formReducer " + event.name + ":" + event.value + ". State:" + state)

    if (event.name == "sessionNotesText") {
        localStorage.setItem('sessionNotesText', event.value);
    }
    if (event.name == "sessionTitle") {
        localStorage.setItem('sessionTitle', event.value);
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

const sendMyPost = (url, token, data) => {
    // Simple POST request with a JSON body using fetch

    const myHeaders = new Headers(
        { Authorization: `Bearer ${token}` },
    )

    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
        method: 'POST',
        // headers: new Headers(
        //     { Authorization: `Bearer ${token}` },
        //     { 'Content-Type': 'application/json' },
        // ),
        headers: myHeaders,
        body: JSON.stringify(data)
    };
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
}

export default function NewSessionNotes() {


    const { id } = useParams()
    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false)

    const [actions, setActions] = useState([]);

    const storedFormTitle = localStorage.getItem('sessionTitle')
    const storedFormNotes = localStorage.getItem('sessionNotesText')

    
    const addNewAction = (text, date, description) => {
        //e.preventDefault();
        console.log("New action: " + text)
        console.log("total actions: " + actions.length  )
    
        const newAction = {
            text: text,
            date: date,
            description: description,
            complete: false
          };
    
        if (newAction.text.trim() !== '') {
          setActions([...actions, newAction]);
          //setNewItem('');
        }
      };

    useEffect(() => {
        const storedActions = localStorage.getItem('storedActions');
        if (storedActions) {
          setActions(JSON.parse(storedActions));
        }
      }, []);
    
    useEffect(() => {
        localStorage.setItem('storedActions', JSON.stringify(actions));
    }, [actions]);

    const handleClear = event => {
        event.preventDefault();
        console.log("Clear Button")
        localStorage.clear()
    }

    const handleSubmit = event => {
        event.preventDefault();
        console.log("Submit button: " + formData + ":" + formData.value)

        console.log("Trying to JSON it " + JSON.stringify(formData))

        var sessionNotes = {}
        var postMessage = {}
        var userJSON = {}

        {Object.entries(formData).map(([name, value]) => {
            console.log("New loop: " + name + ":" + value.toString())
            sessionNotes[name] = value.toString()
        })}

        userJSON["id"] = id
        sessionNotes["users_permissions_user"] = userJSON

        console.log(JSON.stringify(sessionNotes))

        postMessage["data"] = sessionNotes
        console.log("To POST: " + JSON.stringify(postMessage))
        //alert('You have submitted the form.')
        setSubmitting(true);

        const tokenString = sessionStorage.getItem('token');
	    const token = JSON.parse(tokenString);

        sendMyPost("http://localhost:1337/api/session-notes-all", token, postMessage)

        
        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }

    const handleChange = event => {
        console.log("Something changed: " + event.target.name + " " + event.target.value)
        setFormData({
          name: event.target.name,
          value: event.target.value,
        });
      }

    return(
        <Container>
            <h1>New Session</h1>
            {submitting &&
                <div>
                    You are submitting the following:
                    <ul>
                        {Object.entries(formData).map(([name, value]) => (
                            <li key={name}><strong>{name}</strong>: {value.toString()}</li>
                        ))}
                    </ul>
                </div>
            }

            <Row>
            <Col md={8}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="sessionNotesFormTitle">
                    <Form.Label>
                        <p>Title</p> 
                        <input 
                            defaultValue={storedFormTitle}
                            name="sessionTitle" 
                            type="text" onChange={handleChange}
                        />
                    </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="sessionNotesFormDate">
                    <Form.Label><p>Date:</p></Form.Label>
                    <Form.Control name="sessionDate" type="date" onChange={handleChange}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="sessionNotesFormNotes">
                    <Form.Label><p>Notes:</p></Form.Label>
                    <Form.Control 
                        defaultValue={storedFormNotes}
                        name="sessionNotesText" as="textarea" 
                        rows={3} onChange={handleChange} 
                    />
                </Form.Group>
                <Row>
                    <Col><Button type = "submit" >Save</Button></Col>
                    <Col><Button onClick = {handleClear} >Clear</Button></Col>
                
                </Row>
            </Form>
            </Col>
            <Col md={4}>
                    <CaptureAction addNewAction={addNewAction}/> 
                    <ActionList data = {actions} />
            </Col>
            </Row>
            
            
        </Container>
    )
/*
    return(
        <div>
            <h1>New Session</h1>
            {submitting &&
                <div>
                    You are submitting the following:
                    <ul>
                        {Object.entries(formData).map(([name, value]) => (
                            <li key={name}><strong>{name}</strong>: {value.toString()}</li>
                        ))}
                    </ul>
                </div>
            }
            
            <Form onSubmit={handleSubmit}>
                <fieldset>
                    <label>
                        <p>Title</p> <input name="SessionTitle" type="text" onChange={handleChange}/>
                    </label>
                </fieldset>
                <fieldset>
                    <label><p>Date:</p></label>
                    <input name="SessionDate" type="date" onChange={handleChange}/>
                </fieldset>
                <div className="container">
                    <TextEditor />
                </div>

                <Button tyoe= "Submit">Save</Button>
            </Form>
            
        </div>
    )
    */
}