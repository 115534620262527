import React from 'react'
import  { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

const USERS = gql `
	query GetUsers {
		usersPermissionsUsers {
			data {
				id
				attributes {
					username
				}
			}
		}
	}
`


/**
 * 
 * query GetUsers {
  usersPermissionsUsers {
    __typename
    data {
      id
      attributes {
        username,
        email,
        Pronouns,
        headshot {
          __typename
          data {
            __typename
            attributes {
              
              size
              height
              width
              name
              caption
            }
          }
        }
      }
    }
  }
}
 */


export default function Header() {

	const { loading, error, data } = useQuery(USERS)
	if (loading) return <p>Please wait</p>
	if (error) return <p>Error 2 :(</p>

	return (
		<div className="site-header">
			<Link to="/"><h1>MSR Portal</h1></Link>
		</div>
	)

    // <nav className='users'>
	// 	<span>Filter by user:</span>
	// 	{data.usersPermissionsUsers.data.map( user => (
	// 		<Link key={user.id} to={`/User/${user.id}`}>
	// 			{user.attributes.username}
	// 		</Link>
	// 	))}
	// </nav>

}