import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ApolloClient, createHttpLink, ApolloProvider, InMemoryCache } from '@apollo/client'
import 'bootstrap/dist/css/bootstrap.min.css';

import Homepage from 'pages/Homepage'
import Actions from 'pages/Actions'
import Metrics from 'pages/Metrics'
import User from 'pages/User'
import SessionNotes from 'pages/SessionNotes'
import NewSessionNotes from 'pages/NewSessionNotes'
import MyCard from 'pages/MyCard';
import Login from 'components/Login';
import Header from 'components/Header'
import useToken from 'Hooks/useToken';

import { strapi_config } from 'Constants';

import { setContext } from '@apollo/client/link/context';

import 'bootstrap/dist/css/bootstrap.min.css';


const httpLink = createHttpLink({
	uri: strapi_config.url.BASE_STRAPI_URL + '/graphql',
});

  
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const tokenString = sessionStorage.getItem('token');
	const token = JSON.parse(tokenString);
	//const token  = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NTU0OTc2LCJleHAiOjE2NjcxNDY5NzZ9.Y46YRg8BhYd3rpA8DBWa8x3Y7Teu9ducA5TeRGx1F_g"
	console.log("Token: " + token)
	// return the headers to the context so httpLink can read them
	return {
	  headers: {
		...headers,
		authorization: token ? `Bearer ${token}` : "",
	  }
	}
})

const client = new ApolloClient({
	//uri: "http://localhost:1337/graphql",
	link: authLink.concat(httpLink),
	cache: new InMemoryCache()
})

function App() {

	const { token, setToken } = useToken();

	if (!token) {
		return <Login setToken={setToken} />
	}

	console.log("Start of app after login" + client.headers)

	return (
			<ApolloProvider client={client}>
			<div className="App">
				<Header />
				<Routes>
					<Route exact path="/" element={<Homepage />} />
					<Route path="/SessionNotes/:id" element={<SessionNotes />} />
					<Route path="/Actions/:id" element={<Actions />} />
					<Route path="/Metrics/:id" element={<Metrics />} />
					<Route path="/User/:id" element={<User />} />
					<Route path="/NewSessionNotes/:id" element={<NewSessionNotes />} />
                    <Route path="/tabs" element={<MyCard />} />
				</Routes>
			</div>
			</ApolloProvider>
	);
}

export default App;
